<template>
  <div role="group" class="form-group form-row">
    <label v-if="label" :for="name" class="col-form-label" :class="settings.classes.label">{{ $t(label) }}</label>
    <div class="row no-gutters" :class="settings.classes.input">
      <div :class="{ 'col-sm-12': !helper, 'col-sm-5': !!helper }">
        <div class="input-group">
          <button :disabled="$attrs.disabled" type="button" role="button" class="input-group-text" @click="onDecrease"><CIcon name="cil-minus" /></button>
          <div ref="input" class="form-control text-center" :class="{ 'is-invalid': !isValid }">{{ state }}</div>
          <button :disabled="$attrs.disabled" type="button" role="button" class="input-group-text" @click="onIncrease"><CIcon name="cil-plus" /></button>
        </div>
      </div>
      <Label v-if="helper" class="ml-3 col-sm-5 control-helper">{{ $t(helper) }}</Label>
      <template v-if="!isValid">
        <div v-for="(error, index) in errors" :key="index" class="invalid-feedback" style="display: block;">{{ error.message }}</div>
      </template>
    </div>
  </div>
</template>

<script>
const CONFIG = { classes: { input: 'col-sm-9', label: 'col-sm-3' } }

export default {
  name: 'AInputCounter',
  inheritAttrs: true,
  watch: {
    value(val) {
      this.state = parseInt(val)
    }
  },
  props: {
    value: {
      type: [String, Number]
    },
    min: {
      type: [String, Number]
    },
    max: {
      type: [String, Number]
    },
    label: {
      type: String
    },
    horizontal: {
      type: [Boolean, Object],
      default: false
    },
    helper: {
      type: String
    },

    isValid: {
      type: Boolean,
      default: true
    },
    errors: {
      type: Array
    }
  },
  computed: {
    name() {
      return this.$attrs.name || this.$attrs.id || this.$vnode.data.model.expression.split('.').pop()
    },
    name_clean() {
      return this.name.split('.')[0]
    },
    listeners() {
      const { input, change, ...listeners } = this.$listeners
      return listeners
    }
  },
  data() {
    return {
      state: this.value,
      settings: { ...CONFIG }
    }
  },
  created() {
    if (typeof this.horizontal === 'object') this.settings.classes = { ...this.settings.classes, ...this.horizontal }
    if (this.min) this.settings.min = parseInt(this.min)
    if (this.max) this.settings.max = parseInt(this.max)
  },
  methods: {
    onDecrease(e) {
      if (this.settings.min && this.state <= this.settings.min) return
      this.state--
      this.emit(e)
    },
    onIncrease(e) {
      if (this.settings.max && this.state >= this.settings.max) return
      this.state++
      this.emit(e)
    },
    emit(e) {
      this.$emit('change', this.state, e)
      this.$emit('update:value', this.state, e)
    }
  }
}
</script>
